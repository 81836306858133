.applicationIntroductionTitle___FYd8t {
  font-size: 16px;
  color: #1a1a1a;
  letter-spacing: -0.2px;
  margin: 30px 0 12px 0;
}
.applicationVersonFeatures___3375e {
  max-width: 1396px;
  height: auto;
  font-size: 14px;
  color: #666666;
  letter-spacing: -0.17px;
  line-height: 24px;
}
.version___39jTy {
  font-size: 14px;
  color: #1a1a1a;
  letter-spacing: -0.17px;
  line-height: 32px;
}
.versionLine___1Tr1S {
  display: inline-block;
  width: 100%;
  height: 1px;
  background: #e1e6ec;
  margin-top: 30px;
}
.applicationVersonFeatures___3375e {
  white-space: pre-line;
}
