.banner___2F9FX {
  position: relative;
}
.banner___2F9FX .imgGroupStyle___28uYy {
  width: 100%;
  height: 480px;
  position: relative;
  text-align: center;
}
.banner___2F9FX .content___29-Ig {
  width: 100%;
  text-align: center;
  position: absolute;
  top: 34%;
}
.banner___2F9FX .content___29-Ig .sloganInfoStyle___2CKZb {
  font-size: 40px;
  color: #000;
  line-height: 44px;
  width: 100%;
  height: 44px;
  text-align: center;
}
.banner___2F9FX .content___29-Ig .appProfileStyle___q2LBW {
  font-size: 18px;
  color: #000;
  line-height: 32px;
  width: 100%;
  height: 96px;
  text-align: center;
}
.banner___2F9FX .imgBgStyle___1UGfQ {
  height: 480px;
  width: 100%;
  background-repeat: none;
  background-size: auto 100%;
  background-position: center center;
}
.banner___2F9FX .bannerTabStyle___2TQJH {
  font-size: 24px;
  color: #000;
  letter-spacing: -0.17px;
}
.banner___2F9FX .numberStyle___3nCFJ {
  opacity: 0.6;
  font-size: 12px;
  color: #000;
  letter-spacing: -0.09px;
  margin-left: 2px;
}
.banner___2F9FX .clearCursor___1iw7T {
  cursor: auto;
}
.banner___2F9FX .ant-carousel .slick-dots li button {
  background: #000;
}
.banner___2F9FX .ant-carousel .slick-dots li.slick-active button {
  background: #171313;
  opacity: 1;
}
.banner___2F9FX .ant-carousel .slick-dots {
  margin-bottom: 19px;
  margin-left: 370px;
  justify-content: start;
}
.banner___2F9FX .ant-carousel .slick-dots li {
  width: 80px;
  height: 4px;
}
.banner___2F9FX .ant-carousel .slick-dots li button {
  width: 80px;
  height: 4px;
}
.banner___2F9FX .ant-carousel .slick-dots .slick-active {
  width: 80px;
  height: 4px;
}
.banner___2F9FX .ant-carousel .slick-dots .slick-active button {
  width: 80px;
  height: 4px;
}
