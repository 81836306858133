.create-instance-port___1NkIL {
  text-align: center;
  padding: 10px 5px;
}
.create-instance-port___1NkIL .title___5yjNp {
  font-size: 16px;
  font-weight: bold;
  color: #005fe1;
  display: inline-block;
  margin-bottom: 20px;
}
.create-instance-port___1NkIL .box___17fxY {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}
.create-instance-port___1NkIL .box___17fxY .serverName___3PRbI {
  width: 150px;
  height: 90px;
  text-align: center;
  line-height: 90px;
  border: 1px solid #005fe1;
  border-radius: 5px;
}
.create-instance-port___1NkIL .box___17fxY .serverName___3PRbI:hover {
  border-width: 2px;
  height: 88px;
  line-height: 88px;
}
