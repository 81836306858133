.purchase-content___2ejI- {
  min-height: 100vh;
  background: #fff;
  padding-top: 40px;
  padding-bottom: 150px;
}
.purchase-content___2ejI- .itemWrap___3eVPJ {
  margin-top: 12px;
  display: flex;
  flex-flow: row nowrap;
}
.purchase-content___2ejI- .itemWrap___3eVPJ .left___fjstj {
  width: 20%;
  height: 100%;
  margin-right: 5px;
  text-align: right;
  display: inline-block;
  font-size: 14px;
  color: #666;
  letter-spacing: -0.16px;
  line-height: 36px;
  box-sizing: border-box;
  padding-right: 80px;
}
.purchase-content___2ejI- .itemWrap___3eVPJ .right___38Pq6 {
  width: 80%;
  display: inline-block;
  text-align: left;
  font-family: MicrosoftYaHei;
  font-size: 13px;
  color: #1a1a1a;
  letter-spacing: -0.16px;
  line-height: 36px;
}
.purchase-content___2ejI- .itemWrap___3eVPJ .right___38Pq6 .ant-select-auto-complete {
  width: 100%;
}
.purchase-content___2ejI- .itemWrap___3eVPJ .right___38Pq6 .text___owcPv {
  margin-right: 30px;
  font-size: 16px;
}
.purchase-content___2ejI- .itemWrap___3eVPJ .right___38Pq6 .link___2AClQ {
  display: inline-block;
  font-size: 16px;
  margin-right: 30px;
  color: #169bd5;
  cursor: pointer;
}
.purchase-content___2ejI- .itemWrap___3eVPJ .right___38Pq6 .text-area-wrap___NgFYR {
  width: 500px;
}
.purchase-content___2ejI- .auto-complete-wrap___JosBe {
  width: 480px;
  display: flex;
  flex-flow: row nowrap;
}
.purchase-content___2ejI- .ant-select-auto-complete {
  width: 100%;
}
.purchase-content___2ejI- .ant-radio-button-wrapper {
  margin-right: 20px;
  min-width: 120px;
  text-align: center;
}
