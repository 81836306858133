.page-aPPDetail___3tRtU {
  width: 100%;
  background: #fff;
  min-height: calc(100vh - 410px);
  overflow: hidden;
}
.page-aPPDetail___3tRtU .content___AyZEy {
  width: 1280px;
  margin: 0 auto;
}
.page-aPPDetail___3tRtU .content___AyZEy .tabsWrap___qMghQ {
  margin-top: 20px;
}
.page-aPPDetail___3tRtU .content___AyZEy .tabsWrap___qMghQ .ant-tabs-tab {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}
