.pay-button___EXzff .amt-wrap___kMtPW {
  display: flex;
  flex-flow: row nowrap;
  margin-top: 18px;
  height: 48px;
}
.pay-button___EXzff .amt-wrap___kMtPW .hint___3FLhb {
  line-height: 48px;
  font-size: 14px;
  color: #777777;
  letter-spacing: 0;
  margin-right: 26px;
  height: 48px;
}
.pay-button___EXzff .amt-wrap___kMtPW .amt___3i_bp {
  height: 48px;
  font-size: 20px;
  color: #ff4400;
  letter-spacing: 0;
  line-height: 48px;
  font-weight: 500;
}
.pay-button___EXzff .amt-wrap___kMtPW .finOriginalPrice___2-w2n {
  line-height: 48px;
  margin-left: 14px;
  font-size: 14px;
  color: #adafb3;
  letter-spacing: 0;
  text-decoration: line-through;
}
.pay-button___EXzff .ant-btn-error {
  background: #ff4400;
  width: 200px;
  height: 52px;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  border-radius: 8px;
}
.pay-button___EXzff .ant-btn:hover {
  color: #fff;
  border: none;
  background: #ff4400;
  outline: none;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}
