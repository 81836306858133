.devIntroduce___E-LBd {
  background: #f9f9f9;
}
.devIntroduce___E-LBd .content___19_Oj {
  width: 1024px;
  margin: 0 auto;
  height: 194px;
  padding-top: 43px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
}
.devIntroduce___E-LBd .content___19_Oj .left___3GOAG {
  width: 790px;
}
.devIntroduce___E-LBd .content___19_Oj .right___31GkQ {
  width: 108px;
}
