.app-tabs___3mZ3i {
  margin-top: 55px;
  margin-bottom: 120px;
}
.app-tabs___3mZ3i .ant-tabs .ant-tabs-left-bar .ant-tabs-tab {
  text-align: left;
  font-size: 20px;
  padding: 0px 24px;
}
.app-tabs___3mZ3i .ant-tabs-tab-btn {
  font-size: 20px;
}
.app-tabs___3mZ3i .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000000;
  font-size: 20px;
}
.app-tabs___3mZ3i .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn:hover {
  color: #000000;
}
