.my-radio-wrap___3qU9M {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 10px;
}
.my-radio-wrap___3qU9M .my-radio___3X5yk {
  min-width: 138px;
  height: 40px;
  font-size: 14px;
  text-align: center;
  border-radius: 8px !important;
  color: #212224 !important;
  border: 1px solid #dddddd;
  box-shadow: none !important;
  outline: none !important;
  line-height: 38px;
}
.my-radio-wrap___3qU9M .my-radio___3X5yk:not(:first-child)::before {
  width: 0px !important;
}
.my-radio-wrap___3qU9M .ant-radio-button-wrapper-checked {
  background: rgba(29, 206, 219, 0.1) !important;
  border: 1px solid #005fe1 !important;
  box-shadow: none !important;
  color: #212224 !important;
  outline: none !important;
}
.card___1TE-y .my-radio___3X5yk {
  min-width: 138px;
  height: auto;
  font-size: 14px;
  text-align: center;
  border-radius: 0px !important;
  color: #212224 !important;
  border: 1px solid #dddddd;
  box-shadow: none !important;
  outline: none !important;
}
.card___1TE-y .my-radio___3X5yk:not(:first-child)::before {
  width: 0px !important;
}
