.other-app-card___3LhA_ {
  width: 160px;
  height: 176px;
  text-align: center;
  background: #fff;
  cursor: pointer;
}
.other-app-card___3LhA_ .singOtherApp___10lD7 {
  display: inline-block;
  width: 160px;
  height: 176px;
  text-align: center;
  border: 1px solid #d9dfe6;
}
.other-app-card___3LhA_ .singOtherApp___10lD7 .img___2HCYp {
  width: 60px;
  height: 60px;
  margin: 26px 50px 16px 50px;
}
.other-app-card___3LhA_ .singOtherApp___10lD7 .otherAppBottom___2Qrx6 {
  padding: 0 5px;
}
.other-app-card___3LhA_ .singOtherApp___10lD7 .otherAppBottom___2Qrx6 .otherAppTitle___2dXdq {
  font-family: MicrosoftYaHei;
  font-size: 13px;
  text-align: center;
  color: #1a1a1a;
}
.other-app-card___3LhA_ .singOtherApp___10lD7 .otherAppBottom___2Qrx6 .scoreNum___HarjQ {
  color: #1a1a1a;
  vertical-align: middle;
}
.other-app-card___3LhA_ .singOtherApp___10lD7 .otherAppBottom___2Qrx6 .ant-rate {
  font-size: 14px;
}
