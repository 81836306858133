.commentList___3XFNN {
  margin-top: 20px;
}
.commentList___3XFNN .title___1va_v {
  color: #1a1a1a;
}
.commentList___3XFNN .checkMoreWrap___Boq3O {
  margin-top: 40px;
  text-align: center;
}
.commentList___3XFNN .checkMoreWrap___Boq3O .checkMoreBtn___2YUzh {
  display: inline-block;
  color: #4067f2;
  cursor: pointer;
}
