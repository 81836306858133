.modal___19vKo .ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 0px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
}
.modal___19vKo .ant-modal-title {
  font-weight: bold;
}
