.components-page-info___1gPYu {
  cursor: pointer;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf {
  width: 390px;
  height: 180px;
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  border: 1px solid transparent;
  position: relative;
  padding: 0 32px 0 0;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf:hover {
  z-index: 1;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0 4px 10px 0 #eaedf3;
  border-radius: 2px;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf:hover .RightBorder___1EEz9 {
  border-top: 1px solid transparent;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .RightBorder___1EEz9 {
  border-top: 1px solid #ededed;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .sloganPicStyle___1EX8X {
  width: 68px;
  height: 68px;
  background-color: #999;
  border-radius: 12px;
  margin: 18px 28px 80px 20px;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .sloganTitleStyle___rMcHP {
  font-size: 18px;
  color: #000000;
  display: block;
  margin-top: 22px;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .evaluateNumStyle___19pv7 {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #4e4e4e;
  letter-spacing: -0.15px;
  line-height: 15px;
  margin-left: 5px;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .sloganAbstractDiv___3ffJi {
  height: 66px;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .sloganAbstractDiv44___2OD7z {
  height: 44px;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .sloganAbstract___1W796 {
  font-family: MicrosoftYaHei;
  font-size: 13px;
  color: #1a1a1a;
  line-height: 23px;
  width: 240px;
  margin-top: 6px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .clamp2___2iP7H {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #999;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .tags-abstract___14dR4 {
  font-size: 11px;
  color: #6081a1;
  margin-bottom: 6px;
  line-height: 23px;
  overflow: hidden;
  margin-top: 5px;
}
.components-page-info___1gPYu .row-style___3T7BA .divStyle___1EkTf .tags-abstract___14dR4:hover {
  z-index: 1;
  color: #eeecf3;
  background: #578cf0;
  border: 1px solid #ececec;
  box-shadow: 0 0 #eaedf3;
  border-radius: 2px;
  margin-top: 5px;
}
.components-page-info___1gPYu .ant-pagination {
  text-align: right;
}
.components-page-info___1gPYu .ant-rate-star {
  margin-right: 4px;
  font-size: 14px;
}
