.detail___iVUcP {
  padding-bottom: 80px;
}
.detail___iVUcP .content___11677 {
  width: 260px;
  margin: 100px auto;
}
.detail___iVUcP .content___11677 .my-icon___3_w3X {
  display: inline-block;
  font-size: 42px;
  color: yellowgreen;
  margin-right: 10px;
}
.detail___iVUcP .content___11677 .title___1PEDJ {
  display: inline-block;
  font-size: 22px;
  font-weight: bold;
}
.detail___iVUcP .content___11677 .returnBtn___1A0Mm {
  margin-top: 30px;
}
.detail___iVUcP .content___11677 .returnBtn___1A0Mm button {
  width: 100px;
}
.detail___iVUcP .content___11677 .returnBtn___1A0Mm button:nth-child(2) {
  float: right;
}
