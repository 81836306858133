.pay-bar___1OE0j {
  width: 100%;
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: flex;
  height: 0.8rem /* 80/100 */;
  background: #ffffff;
  box-shadow: 3px -3px 8px 0 rgba(0, 0, 0, 0.05);
  padding-top: 15px;
  padding-left: 282px;
  z-index: 10;
  height: 80px;
}
.pay-bar___1OE0j .discount-box___2sATe {
  margin-left: 14px;
}
.pay-bar___1OE0j .discount-box___2sATe .up___147-P {
  font-size: 12px;
  color: #212224;
  letter-spacing: 0;
  margin-top: 8px;
  text-decoration: line-through;
}
.pay-bar___1OE0j .discount-box___2sATe .down___1qjDY {
  font-size: 12px;
  color: #f44236;
  letter-spacing: -0.15px;
  line-height: 20px;
}
.pay-bar___1OE0j .ant-btn-error {
  width: auto;
  background: #FF4400;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  height: 52px;
  border-radius: 8px;
  outline: none;
}
.pay-bar___1OE0j .ant-btn:hover {
  color: #fff;
  border: none;
  background: #ff4400;
  outline: none;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.2);
}
