.coupon___8kABq .text___2fvpo {
  margin-right: 30px;
  font-size: 16px;
}
.coupon___8kABq .link___u5Eg3 {
  display: inline-block;
  font-size: 16px;
  margin-right: 30px;
  color: #169bd5;
  cursor: pointer;
}
