.relation-app-list___1gzI3 {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
}
.relation-app-list___1gzI3 .content___3jx8W {
  width: 1080px;
  margin: 0 auto;
}
.relation-app-list___1gzI3 .content___3jx8W .other-app___2OLLJ {
  display: flex;
  flex-flow: row wrap;
}
.relation-app-list___1gzI3 .content___3jx8W .other-app___2OLLJ > div {
  margin-right: 30px;
  margin-bottom: 20px;
}
.relation-app-list___1gzI3 .slick-arrow {
  width: 40px;
  height: 40px;
  z-index: 100;
}
.relation-app-list___1gzI3 .slick-prev {
  left: -60px;
  background: url('/images/leftarrow_dis.svg') !important;
}
.relation-app-list___1gzI3 .slick-next {
  right: -60px;
  background: url('/images/rightarrow_dis.svg') !important;
}
.relation-app-list___1gzI3 .slick-dots {
  display: none !important;
}
