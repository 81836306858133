.app-unshelf-hint___2DK2E {
  text-align: center;
}
.app-unshelf-hint___2DK2E .unshelf___HNiX2 {
  width: 220px;
  height: 160px;
  margin-top: 100px;
  margin-bottom: 68px;
}
.app-unshelf-hint___2DK2E h4 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.app-unshelf-hint___2DK2E p {
  text-align: center;
  line-height: 1.5;
}
.app-unshelf-hint___2DK2E .hint___2mVr0 {
  margin-bottom: 40px;
}
