.app-card___2-I2v {
  display: flex;
  width: 287px;
  height: 181px;
  box-sizing: border-box;
  border: 1px solid #f0ecec;
  cursor: pointer;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  flex-shrink: 0;
  flex-grow: 0;
}
.app-card___2-I2v:focus {
  text-decoration: none;
}
.app-card___2-I2v .van-image___3YCps {
  height: 100px;
  position: relative;
  display: inline-block;
}
.app-card___2-I2v .van-image___3YCps .img___9np40 {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}
.app-card___2-I2v .bottom___33t3d {
  padding: 5px;
}
.app-card___2-I2v .bottom___33t3d .title___21VD4 {
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  color: #ff5e0b;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.app-card___2-I2v .bottom___33t3d .company___V1ufc {
  text-align: center;
  margin-top: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  color: #888989;
}
