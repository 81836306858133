.buy-button___hc_tA {
  width: 160px;
}
.buy-button___hc_tA .commonImmediatePurchase___3BQoT {
  width: 148px;
  height: 58px;
  border-radius: 32px;
  color: #fff;
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold;
  font-weight: 600;
  margin: 15px 0;
}
.buy-button___hc_tA .immediatePurchase___28aNz {
  background-image: linear-gradient(-180deg, #fca138 7%, #f76b1c 93%);
}
.buy-button___hc_tA .immediatePurchase___28aNz:focus,
.buy-button___hc_tA .immediatePurchase___28aNz:hover {
  border: none;
  background-image: linear-gradient(-180deg, #fca138 7%, #f76b1c 93%);
  color: #fff;
}
