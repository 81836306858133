.page-aPPSolutionDetail___RdBNk {
  min-width: 980px;
  background: #fff;
  box-sizing: border-box;
  padding-bottom: 100px;
  min-height: calc(100vh - 410px);
}
.page-aPPSolutionDetail___RdBNk .banner-wrap___318Iz {
  min-height: 480px;
  background: #013180;
}
.page-aPPSolutionDetail___RdBNk .banner___2txRj {
  width: 100%;
  height: 480px;
  background-position: center !important;
  background-size: auto 100% !important;
  background-repeat: no-repeat !important;
}
.page-aPPSolutionDetail___RdBNk .banner___2txRj .banner-content___Jrhhg {
  width: 1200px;
  margin: 0 auto;
}
.page-aPPSolutionDetail___RdBNk .banner___2txRj .banner-content___Jrhhg .slogan-title___3xlJ7 {
  font-size: 40px;
  color: #ffffff;
  line-height: 52px;
  padding-top: 116px;
}
.page-aPPSolutionDetail___RdBNk .banner___2txRj .banner-content___Jrhhg .slogan-short-info___HRdwK {
  font-size: 16px;
  color: #ffffff;
  line-height: 25px;
  margin-top: 50px;
  width: 500px;
  max-height: 130px;
  overflow: hidden;
  margin-bottom: 30px;
}
.page-aPPSolutionDetail___RdBNk .banner___2txRj .banner-content___Jrhhg .slogan-info___2SVu4 {
  cursor: pointer;
  font-size: 16px;
  color: #ffffff;
  line-height: 25px;
  margin-top: 50px;
  width: 500px;
  max-height: 130px;
  overflow: hidden;
  margin-bottom: 30px;
}
.page-aPPSolutionDetail___RdBNk .banner___2txRj .banner-content___Jrhhg .slogan-info___2SVu4::after {
  content: " ...";
  position: relative;
  bottom: 0;
  right: 0;
  padding-left: 1px;
}
.page-aPPSolutionDetail___RdBNk .center___2lXRu {
  overflow: hidden;
  width: 1280px;
  margin: 0 auto;
  margin-top: 30px;
}
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 {
  font-size: 14px;
  font-weight: revert;
}
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 h1,
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 h2,
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 h3,
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 h4,
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 h5,
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 h6 {
  font-size: revert;
  font-weight: revert;
  padding: 20px 0;
}
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 p {
  line-height: 25px;
}
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 ul {
  list-style: disc;
  line-height: 25px;
}
.page-aPPSolutionDetail___RdBNk .center___2lXRu .mark___1aBn9 ol {
  list-style: decimal;
  line-height: 25px;
}
