.instance-select___2uYlD {
  width: 100%;
  max-width: 600px;
}
.instance-select___2uYlD .auto-complete-wrap___1CQoh {
  display: flex;
  flex-flow: row nowrap;
}
.instance-select___2uYlD .ant-select-auto-complete {
  width: 100%;
}
.options-wrap___hU5Jq {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.ant-tooltip-inner {
  width: 280px;
}
