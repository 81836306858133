.coupons-card___33d-3 {
  display: block;
  padding: 0;
  text-align: left;
  border: none;
  outline: none;
  cursor: pointer;
  width: 350px;
  box-sizing: border-box;
  background: #fff;
  border-radius: 4px 4px 4px 4px;
  padding-bottom: 16px;
  margin-right: 16px;
  line-height: 1;
  position: relative;
  margin-bottom: 16px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.3);
  height: 215px;
}
.coupons-card___33d-3 .discount-name___3vDlF {
  height: 32px;
  background: #005fe173;
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 32px;
  padding: 0 16px;
  border-radius: 4px 4px 0 0;
}
.coupons-card___33d-3 .content___1GjPS {
  padding: 0 16px;
  height: 164px;
}
.coupons-card___33d-3 .content___1GjPS .code___38hpN {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #8d9197;
  line-height: 20px;
  margin-top: 10px;
}
.coupons-card___33d-3 .content___1GjPS .discount-value___3ZcWE {
  font-size: 32px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #005fe173;
  line-height: 32px;
  margin-top: 18px;
  margin-bottom: 18px;
}
.coupons-card___33d-3 .content___1GjPS .wrap___2s1nw {
  flex-flow: row nowrap;
  justify-content: space-between;
  font-size: 12px;
  line-height: 18px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #46494e;
  margin-bottom: 10px;
}
.coupons-card___33d-3 .content___1GjPS .wrap___2s1nw .warn___8BYzK {
  color: #ff3b30;
}
.coupons-card___33d-3 .content___1GjPS .divide___3lGD4 {
  width: 100%;
  height: 1px;
  background: #dfe2e5;
}
.coupons-card___33d-3 .content___1GjPS .bottom___3khRz {
  margin-top: 11px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}
.coupons-card___33d-3 .content___1GjPS .bottom___3khRz .type___1LGzi {
  font-size: 12px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #46494e;
}
.coupons-card___33d-3 .content___1GjPS .bottom___3khRz .link___11yOH {
  margin-top: -10px;
  width: 50px;
  line-height: 30px;
}
.disabled___DT1RJ {
  cursor: default;
}
.disabled___DT1RJ .discount-name___3vDlF {
  background: #becfd8;
}
.disabled___DT1RJ .content___1GjPS .code___38hpN {
  color: #becfd8;
}
.disabled___DT1RJ .content___1GjPS .discount-value___3ZcWE {
  color: #becfd8;
}
.disabled___DT1RJ .content___1GjPS .expires___16d0Z {
  color: #becfd8;
}
.disabled___DT1RJ .content___1GjPS .type___1LGzi {
  color: #becfd8;
}
.disabled___DT1RJ .img___1hWuj {
  position: absolute;
  bottom: 0;
  right: 8px;
  width: 96px;
  height: 80px;
}
.selected___1i0b6 {
  border: 2px solid #1dc5e7;
  border-top: 1px solid #1dc5e7;
  border-radius: 0px;
}
.selected___1i0b6 .discount-name___3vDlF {
  border-radius: 0px;
  position: relative;
}
.selected___1i0b6 .discount-name___3vDlF .selected-icon___1tR7X {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 0;
  top: 0;
}
.defaultCursor___rLF-q {
  cursor: default;
}
