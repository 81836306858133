.my-radio___TSYiX .ant-radio-group .ant-radio-button-wrapper {
  outline: none;
  border-radius: 16px;
  margin-right: 16px;
  background: #c6c9cf;
  color: #92969f;
}
.my-radio___TSYiX .ant-radio-group .ant-radio-button-wrapper:not(:first-child)::before {
  display: none;
}
.my-radio___TSYiX .ant-radio-group .ant-radio-button-wrapper-checked {
  outline: none !important;
  background: #6694fb;
  color: #fff;
}
