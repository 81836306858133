.other-app-list___2lf1u {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 60px;
}
.other-app-list___2lf1u .hint___2yRcO {
  font-size: 24px;
  color: #1a1a1a;
  letter-spacing: -0.3px;
  line-height: 32px;
  height: 32px;
  margin-bottom: 34px;
}
.other-app-list___2lf1u .content___2gM5W {
  width: 1080px;
  margin: 0 auto;
}
.other-app-list___2lf1u .content___2gM5W .other-app___3524e {
  display: flex;
  flex-flow: row wrap;
}
.other-app-list___2lf1u .content___2gM5W .other-app___3524e > div {
  margin-right: 30px;
  margin-bottom: 20px;
}
.other-app-list___2lf1u .slick-arrow {
  width: 40px;
  height: 40px;
  z-index: 100;
}
.other-app-list___2lf1u .slick-prev {
  left: -60px;
  background: url('/images/leftarrow_dis.svg') !important;
}
.other-app-list___2lf1u .slick-next {
  right: -60px;
  background: url('/images/rightarrow_dis.svg') !important;
}
.other-app-list___2lf1u .slick-dots {
  display: none !important;
}
