.app-basic-info___3lpRY {
  display: flex;
  flex-flow: row nowrap;
  padding: 32px 0;
}
.app-basic-info___3lpRY .img-warp___1xYhj {
  width: 280px;
  height: 204px;
  margin-right: 45px;
}
.app-basic-info___3lpRY .img-warp___1xYhj img {
  width: 280px;
  height: 204px;
  object-fit: scale-down;
}
.app-basic-info___3lpRY .desc___1-_C8 {
  padding-top: 30px;
}
.app-basic-info___3lpRY .desc___1-_C8 .title___f2pVz {
  font-size: 30px;
  color: #212224;
  height: 40px;
  line-height: 40px;
  margin-bottom: 32px;
}
.app-basic-info___3lpRY .desc___1-_C8 > p {
  height: 20px;
  margin-bottom: 10px;
}
.app-basic-info___3lpRY .desc___1-_C8 .label___2Ifza {
  display: inline-block;
  font-size: 16px;
  color: #8e8e93;
  margin-right: 20px;
}
.app-basic-info___3lpRY .desc___1-_C8 .text___3WGAu:focus {
  text-decoration: none;
}
