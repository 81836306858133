.collect-app___3i1L_ {
  width: 80px;
  text-align: center;
  margin-left: auto;
  margin-right: 180px;
  cursor: pointer;
  flex-shrink: 0;
}
.collect-app___3i1L_ .collect___1bkF7 {
  color: #666666;
  margin-left: 10px;
}
.collect-app___3i1L_ .collected___3Hsqq {
  color: #666666;
  margin-left: 10px;
}
