.priceTableWrap___2wUBd {
  margin-top: 25px;
}
.priceTableWrap___2wUBd .title___11ClJ {
  color: #1a1a1a;
  font-size: 18px;
}
.priceTableWrap___2wUBd .rule___wFR1w {
  color: #1a1a1a;
  font-size: 14px;
  margin-top: 8px;
}
.priceTableWrap___2wUBd .wrap___Yn3A3 {
  margin-top: 10px;
}
.priceTableWrap___2wUBd .wrap___Yn3A3 .ul___3SwZJ {
  display: inline-block;
  border: 1px solid #ced3db;
  border-right: none;
  margin-bottom: 10px;
}
.priceTableWrap___2wUBd .wrap___Yn3A3 .ul___3SwZJ .li___1yXBc {
  display: flex;
  flex-flow: row nowrap;
}
.priceTableWrap___2wUBd .wrap___Yn3A3 .ul___3SwZJ .li___1yXBc .td___LXGsU {
  flex-shrink: 1;
  display: inline-block;
  width: 260px;
  height: 60px;
  text-align: center;
  line-height: 60px;
  font-size: 14px;
  border-right: 1px solid #ced3db;
}
.priceTableWrap___2wUBd .wrap___Yn3A3 .ul___3SwZJ .li___1yXBc .td___LXGsU .num___2kwk2 {
  font-size: 20px;
  font-weight: 500;
  display: inline-block;
  margin-right: 6px;
}
