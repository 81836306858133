.App-index___v_qBv {
  min-width: 980px;
  background: #fff;
  box-sizing: border-box;
  padding-bottom: 100px;
  min-height: calc(100vh - 410px);
}
.App-index___v_qBv .banner-wrap___1p9Eg {
  min-height: 480px;
  background: #013180;
}
.App-index___v_qBv .banner___3CKGE {
  width: 100%;
  height: 480px;
  background-position: center !important;
  background-size: auto 100% !important;
  background-repeat: no-repeat !important;
}
.App-index___v_qBv .banner___3CKGE .banner-content___vvLiW {
  width: 1200px;
  margin: 0 auto;
}
.App-index___v_qBv .banner___3CKGE .banner-content___vvLiW .slogan-title___34P6h {
  font-size: 40px;
  color: #ffffff;
  line-height: 52px;
  padding-top: 116px;
}
.App-index___v_qBv .banner___3CKGE .banner-content___vvLiW .slogan-info___5mCDB {
  font-size: 22px;
  color: #ffffff;
  line-height: 1.5;
  margin-top: 50px;
}
.App-index___v_qBv .center___1fAnY {
  padding: 36px 60px 40px 60px;
  overflow: hidden;
}
.App-index___v_qBv .center___1fAnY .search___3UC9v {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 45px;
}
.App-index___v_qBv .center___1fAnY .search___3UC9v input {
  height: 48px;
}
.App-index___v_qBv .center___1fAnY .search___3UC9v .nav___2DePi {
  height: 48px;
  line-height: 48px;
  margin-left: auto;
}
.App-index___v_qBv .center___1fAnY .title-right___1PnU3 {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
}
.App-index___v_qBv .center___1fAnY .title-right___1PnU3 .more____K3MZ {
  font-size: 16px;
  color: #9599a2;
  margin-left: 20px;
  cursor: pointer;
  flex-shrink: 0;
}
.App-index___v_qBv .center___1fAnY .title-right___1PnU3 .more____K3MZ::after {
  content: '>';
  color: #9599a2;
}
