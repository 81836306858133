.page-aPPSolutionSearch___3NIlO {
  min-height: calc(100vh - 410px);
  min-width: 980px;
  background: #fff;
}
.page-aPPSolutionSearch___3NIlO .content___ZbCAH {
  box-sizing: border-box;
  padding: 20px 46px 100px 46px;
}
.page-aPPSolutionSearch___3NIlO .content___ZbCAH .title___20Em- {
  font-size: 28px;
  color: #212224;
  line-height: 38px;
  padding-top: 20px;
}
.page-aPPSolutionSearch___3NIlO .content___ZbCAH .search-hint___RWGga {
  font-size: 14px;
  margin-bottom: 40px;
}
