.page-aPPDeveInfo___3RbgU {
  position: relative;
  padding-bottom: 64px;
  background: #fff;
  min-height: calc(100vh - 410px);
}
.page-aPPDeveInfo___3RbgU .tabWrap___3piV9 {
  width: 1200px;
  margin: 0 auto;
  margin-top: 24px;
}
.page-aPPDeveInfo___3RbgU .tabWrap___3piV9 .ant-tabs-nav-list {
  margin-left: 550px;
}
.page-aPPDeveInfo___3RbgU .tabWrap___3piV9 .ant-tabs-tab {
  font-size: 16px;
  letter-spacing: -0.2px;
  line-height: 36px;
  font-weight: bold;
}
