.avatarWrap___3GCxp {
  display: inline-block;
  vertical-align: 37px;
  margin-right: 45px;
}
.content___PZNBt {
  display: inline-block;
}
.content___PZNBt .title___5Z71o {
  font-size: 22px;
  color: #1a1a1a;
  letter-spacing: -0.28px;
}
.content___PZNBt .type___37Zls {
  font-size: 12px;
  color: #999999;
  letter-spacing: -0.15px;
  line-height: 32px;
  height: 32px;
}
.content___PZNBt .type___37Zls .lv___wAwaF {
  display: inline-block;
  padding: 0 12px;
  background: #ffcc00;
  height: 24px;
  line-height: 24px;
  border-radius: 32px;
  color: #fff;
  margin-left: 10px;
}
.content___PZNBt .info___sTRLW {
  display: inline-block;
}
.content___PZNBt .info___sTRLW .label___3KIfZ {
  font-size: 12px;
  color: #999999;
  letter-spacing: -0.15px;
  line-height: 32px;
  height: 32px;
  display: inline-block;
  margin-right: 10px;
  vertical-align: 2px;
}
.content___PZNBt .info___sTRLW .num___2tzWw {
  font-size: 22px;
  color: #1a1a1a;
  letter-spacing: -0.28px;
  line-height: 32px;
  display: inline-block;
  height: 32px;
  margin-right: 75px;
}
