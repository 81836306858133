.content___11sPz {
  margin: 0 auto;
  padding-bottom: 80px;
  width: 1100px;
}
.content___11sPz .title___2m4dy {
  font-size: 20px;
  font-weight: bold;
  color: #212224;
  margin-top: 30px;
  margin-bottom: 14px;
}
.content___11sPz .submit-btn___3zMTI {
  width: 150px;
}
