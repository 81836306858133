.search-input___1qp5M {
  margin-top: 20px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  height: 50px;
  padding-left: 2px;
}
.search-input___1qp5M .dev-input___YO2cA {
  width: 1100px;
  height: 50px;
  top: 0px;
  right: 50px;
}
.search-input___1qp5M .dev-input___YO2cA .ant-select-arrow {
  display: none;
}
.search-input___1qp5M .dev-input___YO2cA .ant-select-selection-search {
  line-height: 50px !important;
}
.search-input___1qp5M .dev-input___YO2cA .ant-select-selection-placeholder {
  line-height: 50px !important;
}
.search-input___1qp5M .dev-input___YO2cA .ant-select-selector {
  background-color: #f0f1f5;
}
.search-input___1qp5M .ant-input {
  width: 1100px;
  height: 50px;
  background-color: #f0f1f5;
  border-radius: 0px;
  box-shadow: none;
  border: none;
}
.search-input___1qp5M .ant-select-selector {
  height: 50px !important;
}
.search-input___1qp5M .ant-select-selection-item {
  height: 50px;
  line-height: 50px !important;
}
.search-input___1qp5M .searchLogoStyle___2wBlT {
  background-color: #000000;
  padding: 13px;
  width: 50px;
  height: 50px;
}
.search-input___1qp5M .searchLogoStyle___2wBlT:hover {
  background-color: #666666;
  cursor: pointer;
}
