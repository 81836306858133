.page-orderDetail___102p9 {
  min-height: calc(100vh - 410px);
  background: #eceef3;
}
.page-orderDetail___102p9 .center___1VEYr {
  max-width: 860px;
  background: #fff;
  margin: 0 auto;
  padding-bottom: 30px;
  margin-top: 40px;
  margin-bottom: 40px;
}
.page-orderDetail___102p9 .title___3pNpS {
  height: 105px;
  line-height: 105px;
  font-size: 28px;
  color: #212224;
  border: 1px solid #eceef3;
  padding: 0 67px;
}
.page-orderDetail___102p9 .appInfo___1SjtP {
  padding: 20px 67px;
}
.page-orderDetail___102p9 .appInfo___1SjtP .avatarWrap___1hRQe {
  display: inline-block;
  vertical-align: top;
}
.page-orderDetail___102p9 .appInfo___1SjtP .avaRight___1IaIq {
  display: inline-block;
  margin-left: 16px;
  width: 440px;
  vertical-align: 17px;
  position: relative;
}
.page-orderDetail___102p9 .appInfo___1SjtP .avaRight___1IaIq .appName___3C7y- {
  font-size: 16px;
  color: #1a1a1a;
  letter-spacing: -0.2px;
  line-height: 32px;
  height: 32px;
}
.page-orderDetail___102p9 .appInfo___1SjtP .avaRight___1IaIq .appDescribe___2gR1z {
  width: 440px;
  font-size: 13px;
  color: #666666;
  letter-spacing: -0.16px;
  line-height: 24px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.page-orderDetail___102p9 .appInfo___1SjtP .itemWrap___1_UuM .item___yAd4K {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 0;
}
.page-orderDetail___102p9 .appInfo___1SjtP .itemWrap___1_UuM .item___yAd4K .key___3n95e {
  width: 140px;
  min-height: 30px;
  font-size: 14px;
  color: #9ea0a5;
  line-height: 30px;
}
.page-orderDetail___102p9 .appInfo___1SjtP .itemWrap___1_UuM .item___yAd4K .value___2elxV {
  width: 600px;
  min-height: 30px;
  font-size: 14px;
  color: #3e3f42;
  line-height: 30px;
}
