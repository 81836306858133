.title-bar___1GNvx {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  margin-bottom: 12px;
}
.title-bar___1GNvx .title___3Vuab {
  min-width: 112px;
  height: 38px;
  font-size: 22px;
  color: #212224;
  line-height: 38px;
  font-weight: bold;
  margin-right: 60px;
}
