.search-result___3kQCd {
  min-width: 980px;
  background: #fff;
  min-height: calc(100vh - 410px);
}
.search-result___3kQCd .content___39wIJ {
  box-sizing: border-box;
  padding: 20px 46px 100px 46px;
}
.search-result___3kQCd .content___39wIJ .title___l5rhj {
  font-size: 28px;
  color: #212224;
  line-height: 38px;
  padding-top: 20px;
}
.search-result___3kQCd .content___39wIJ .search-hint___3v09g {
  font-size: 14px;
  margin-bottom: 40px;
}
