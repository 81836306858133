.devInfoBox___1Bz9- {
  margin-top: 10px;
}
.devInfoBox___1Bz9- .title___1r_pT {
  margin: 0 30px 0 10px;
  display: inline-block;
  line-height: 40px;
  color: #1a1a1a;
}
.devInfoBox___1Bz9- .title___1r_pT a {
  color: #6694fb;
  cursor: pointer;
}
.hoverBox___3n6UQ {
  width: 380px;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 20px;
}
.hoverBox___3n6UQ .devName___3iQMa {
  font-family: MicrosoftYaHei;
  font-size: 22px;
  color: #1a1a1a;
  letter-spacing: -0.28px;
  line-height: 32px;
  margin-top: 11px;
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
  margin-right: 12px;
}
.hoverBox___3n6UQ .type___1Xaba {
  text-align: center;
  font-size: 12px;
  color: #999999;
  letter-spacing: -0.15px;
  line-height: 32px;
  height: 32px;
}
.hoverBox___3n6UQ .type___1Xaba span {
  display: inline-block;
  font-size: 12px;
  font-weight: normal;
  line-height: 24px;
  height: 24px;
  text-align: center;
  background: #ffcc00;
  border-radius: 32px;
  padding: 0 8px;
  box-sizing: border-box;
  margin-left: 10px;
  color: #fff;
}
.hoverBox___3n6UQ .infoBox___hTFGr {
  width: 200px;
  display: flex;
  flex-flow: nowrap row;
  justify-content: center;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 48px;
}
.hoverBox___3n6UQ .divide___1_VIB {
  height: 40px;
  width: 1px;
  background: #999;
  align-self: center;
}
.hoverBox___3n6UQ .left___1_L0u {
  padding-right: 20px;
}
.hoverBox___3n6UQ .left___1_L0u p {
  color: #999;
  margin-bottom: 0px;
}
.hoverBox___3n6UQ .left___1_L0u span {
  color: #1a1a1a;
  font-size: 22px;
}
.hoverBox___3n6UQ .right___3VT-x {
  padding-left: 20px;
}
.hoverBox___3n6UQ .right___3VT-x p {
  color: #999;
  margin-bottom: 0px;
}
.hoverBox___3n6UQ .right___3VT-x span {
  color: #1a1a1a;
  font-size: 22px;
}
