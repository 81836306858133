.commentList___2NoWm {
  width: 100%;
  border-bottom: 1px solid rgba(178, 186, 194, 0.15);
  padding-top: 18px;
  padding-bottom: 18px;
}
.commentList___2NoWm .contentWrap___1MGZP {
  display: flex;
  flex-flow: row nowrap;
}
.commentList___2NoWm .contentWrap___1MGZP .avatarWrap___20Zv6 {
  margin-right: 16px;
  vertical-align: top;
}
.commentList___2NoWm .contentWrap___1MGZP .content___-nNj6 {
  min-height: 100px;
}
.commentList___2NoWm .contentWrap___1MGZP .content___-nNj6 .info___AVJHS {
  color: #999999;
}
.commentList___2NoWm .contentWrap___1MGZP .content___-nNj6 .info___AVJHS .name___247w7 {
  margin-right: 10px;
}
.commentList___2NoWm .contentWrap___1MGZP .content___-nNj6 .myRate___eE_17 {
  font-size: 12px;
}
.commentList___2NoWm .contentWrap___1MGZP .content___-nNj6 .evaluateInfo___1vjir {
  margin-top: 14px;
}
