.app-info___39DuY {
  display: flex;
  flex-flow: row nowrap;
}
.app-info___39DuY .logo___2hcVh {
  width: 96px;
  height: 96px;
  border-radius: 12px;
  margin-right: 30px;
}
.app-info___39DuY .appTitle___2M1vk {
  color: #1a1a1a;
  font-size: 24px;
  height: 32px;
  line-height: 32px;
  margin-bottom: 7px;
}
.app-info___39DuY .appProfile___1z5Bu {
  height: auto;
  line-height: 24px;
  color: #666;
  padding-top: 16px;
}
.app-info___39DuY .sassApp___1639m {
  margin-top: 6px;
  color: #666;
}
