.commentGrade___2EeLu {
  width: 100%;
  border-bottom: 1px solid rgba(178, 186, 194, 0.15);
  padding-bottom: 40px;
}
.commentGrade___2EeLu .title___3MI3u {
  color: #1a1a1a;
}
.commentGrade___2EeLu .left___3o2Q8 {
  display: inline-block;
  width: 300px;
  margin: 20px 40px 20px 40px;
}
.commentGrade___2EeLu .left___3o2Q8 .score___uslnm {
  text-align: center;
  font-size: 40px;
  color: #1a1a1a;
}
.commentGrade___2EeLu .left___3o2Q8 .rate___Mr8Sq {
  text-align: center;
  margin-bottom: 30px;
}
.commentGrade___2EeLu .left___3o2Q8 .scoreNum___1YbbY {
  text-align: center;
  color: #666;
}
.commentGrade___2EeLu .right___7qBVH {
  display: inline-block;
  width: 440px;
}
.commentGrade___2EeLu .right___7qBVH .progressWrap___rQN-B {
  display: inline-block;
  width: 420px;
  margin-left: 10px;
  margin-bottom: 10px;
}
.commentGrade___2EeLu .right___7qBVH .progressWrap___rQN-B .progressBox___2Fbg3 {
  display: inline-block;
  width: 350px;
  margin-right: 10px;
}
.commentGrade___2EeLu .right___7qBVH .progressWrap___rQN-B .progressBox___2Fbg3 .ant-progress-inner {
  border-radius: 0px !important;
}
.commentGrade___2EeLu .right___7qBVH .progressWrap___rQN-B .progressBox___2Fbg3 .ant-progress-bg {
  height: 16px !important;
  background-color: rgba(0, 153, 255, 0.5);
  border-radius: 0px !important;
}
.commentGrade___2EeLu .right___7qBVH .score4___1f12z .ant-progress-bg {
  background-color: rgba(0, 153, 255, 0.35);
}
.commentGrade___2EeLu .right___7qBVH .score3___dTTHk .ant-progress-bg {
  background-color: rgba(0, 153, 255, 0.24);
}
.commentGrade___2EeLu .right___7qBVH .score2___1FJQE .ant-progress-bg {
  background-color: rgba(0, 153, 255, 0.18);
}
.commentGrade___2EeLu .right___7qBVH .score1___34_On .ant-progress-bg {
  background-color: rgba(0, 153, 255, 0.12);
}
.ant-tooltip-content {
  background: rgba(126, 125, 125);
}
.ant-tooltip-content .ant-tooltip-arrow {
  background: rgba(126, 125, 125);
  border: 1px solid rgba(126, 125, 125);
}
.ant-tooltip-content .ant-tooltip-inner {
  background: rgba(126, 125, 125);
}
