.row-style___13eUv {
  cursor: pointer;
}
.row-style___13eUv a:nth-child(1) .divStyle___1xqQ_ .RightBorder___3ZM4F,
.row-style___13eUv a:nth-child(2) .divStyle___1xqQ_ .RightBorder___3ZM4F,
.row-style___13eUv a:nth-child(3) .divStyle___1xqQ_ .RightBorder___3ZM4F {
  border-top: none;
}
.row-style___13eUv .divStyle___1xqQ_ {
  width: 390px;
  height: 180px;
  background: #ffffff;
  border-radius: 2px;
  display: flex;
  border: 1px solid transparent;
  position: relative;
  padding: 0 32px 0 0;
}
.row-style___13eUv .divStyle___1xqQ_:hover {
  z-index: 1;
  background: #ffffff;
  border: 1px solid #ececec;
  box-shadow: 0 4px 10px 0 #eaedf3;
  border-radius: 2px;
}
.row-style___13eUv .divStyle___1xqQ_:hover .RightBorder___3ZM4F {
  border-top: 1px solid transparent;
}
.row-style___13eUv .RightBorder___3ZM4F {
  border-top: 1px solid #ededed;
}
.row-style___13eUv .sloganPicStyle___13lxU {
  background-color: #999;
  border-radius: 12px;
  margin: 18px 28px 80px 20px;
}
.row-style___13eUv .sloganTitleStyle___Uckjd {
  font-family: MicrosoftYaHei;
  font-size: 18px;
  color: #000000;
  display: block;
  margin-top: 22px;
}
.row-style___13eUv .sloganAbstractDiv___3R-rA {
  height: 66px;
}
.row-style___13eUv .sloganAbstractDiv44___2q5W_ {
  height: 44px;
}
.row-style___13eUv .sloganAbstract___2TyRH {
  font-family: MicrosoftYaHei;
  font-size: 13px;
  color: #1a1a1a;
  line-height: 23px;
  width: 240px;
  margin-top: 6px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}
.row-style___13eUv .clamp2___1mdAH {
  -webkit-line-clamp: 2;
}
.row-style___13eUv .starStyle___1UHPK .ant-rate-star {
  margin-right: 4px;
}
.row-style___13eUv .evaluateNumStyle___3x9-1 {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #4e4e4e;
  letter-spacing: -0.15px;
  line-height: 15px;
  margin-left: 5px;
}
.row-style___13eUv .tags-abstract___3GCVj {
  font-family: MicrosoftYaHei;
  font-size: 11px;
  color: #6081a1;
  margin-bottom: 6px;
  line-height: 23px;
  overflow: hidden;
  margin-top: 5px;
}
.row-style___13eUv .tags-abstract___3GCVj:hover {
  z-index: 1;
  color: #eeecf3;
  background: #578cf0;
  border: 1px solid #ececec;
  box-shadow: 0 0 #eaedf3;
  border-radius: 2px;
  margin-top: 5px;
}
