.detail___98vUY {
  padding-bottom: 80px;
  background: #fff;
}
.detail___98vUY .content___ut_0t {
  width: 1100px;
  margin: 0 auto;
}
.detail___98vUY .content___ut_0t .divide___XNh3p {
  width: 100%;
  height: 1px;
  background: #e5e5ea;
}
.detail___98vUY .content___ut_0t .title___hWzP6 {
  font-size: 20px;
  font-weight: bold;
  color: #212224;
  margin-top: 30px;
  margin-bottom: 14px;
}
.detail___98vUY .content___ut_0t .desc___1f3gu {
  font-size: 14px;
  color: #212224;
  line-height: 22px;
  margin-bottom: 32px;
}
