.app-card___1c1TX {
  display: inline-block;
  width: 400px;
  height: 200px;
  box-sizing: border-box;
  flex-direction: column;
  margin: 10px;
  padding: 10px;
  flex-shrink: 0;
  flex-grow: 0;
  box-shadow: 1px 1px 8px 1px #8888883b;
  overflow: hidden;
}
.app-card___1c1TX:hover {
  box-shadow: 1px 1px 8px 1px #888888;
}
.app-card___1c1TX:focus {
  text-decoration: none;
}
.app-card___1c1TX .title___2_24a {
  font-size: 22px;
  display: block;
  padding-bottom: 20px;
}
.app-card___1c1TX .clasify___E9-fr {
  border-width: 0px;
  position: absolute;
  top: -10px;
  padding: 0 10px;
  height: 29px;
  font-family: 'PingFangSC-Regular', 'PingFang SC', sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #ffffff;
  right: -10px;
  background: #005fe1;
  text-align: center;
  line-height: 29px;
}
.card-font___vrxn5 {
  position: relative;
  line-height: 20px;
  max-height: 60px;
  overflow: hidden;
}
.card-font___vrxn5::after {
  content: ' ...';
  position: relative;
  bottom: 0;
  right: 0;
  padding-left: 1px;
}
.ghost-button___3S0w5 .ant-btn-background-ghost.ant-btn-primary {
  position: relative;
  top: 10px;
  border-radius: 30px;
}
