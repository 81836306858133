.app-more___3mMs3 {
  background: #fff;
}
.app-more___3mMs3 .content___1u6VV {
  box-sizing: border-box;
  padding: 60px 46px 100px 46px;
}
.app-more___3mMs3 .title___18WkA {
  font-size: 28px;
  color: #212224;
  line-height: 38px;
  padding-bottom: 30px;
}
