.page-aPPSolution___1bjqV {
  min-height: calc(100vh - 410px);
  min-width: 1280px;
  background: #fff;
  box-sizing: border-box;
  padding-bottom: 100px;
}
.page-aPPSolution___1bjqV .banner-wrap___1rSOh {
  min-height: 480px;
  background: #013180;
}
.page-aPPSolution___1bjqV .banner___2Tkm0 {
  width: 100%;
  height: 480px;
  background-position: center !important;
  background-size: auto 100% !important;
  background-repeat: no-repeat !important;
}
.page-aPPSolution___1bjqV .banner___2Tkm0 .banner-content___1utgX {
  width: 1280px;
  margin: 0 auto;
}
.page-aPPSolution___1bjqV .banner___2Tkm0 .banner-content___1utgX .slogan-title___3oC_z {
  font-size: 40px;
  color: #ffffff;
  line-height: 52px;
  padding-top: 116px;
}
.page-aPPSolution___1bjqV .banner___2Tkm0 .banner-content___1utgX .slogan-info___2N3jg {
  font-size: 22px;
  color: #ffffff;
  line-height: 1.5;
  margin-top: 50px;
  cursor: pointer;
}
.page-aPPSolution___1bjqV .center___340UZ {
  overflow: hidden;
  width: 1280px;
  margin: 0 auto;
  margin-top: 30px;
}
.page-aPPSolution___1bjqV .center___340UZ .search___1o1MY {
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 45px;
}
.page-aPPSolution___1bjqV .center___340UZ .search___1o1MY input {
  height: 48px;
}
.page-aPPSolution___1bjqV .center___340UZ .search___1o1MY .nav___3W-t5 {
  height: 48px;
  line-height: 48px;
  margin-left: auto;
}
.page-aPPSolution___1bjqV .center___340UZ .title-right___iioYz {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-grow: 1;
}
.page-aPPSolution___1bjqV .center___340UZ .title-right___iioYz .more___2hc2B {
  font-size: 16px;
  color: #9599a2;
  margin-left: 20px;
  cursor: pointer;
  flex-shrink: 0;
}
.page-aPPSolution___1bjqV .center___340UZ .title-right___iioYz .more___2hc2B::after {
  content: '>';
  color: #9599a2;
}
