.devInfo___3Hde- {
  width: 694px;
  margin: 0 auto;
}
.devInfo___3Hde- .title___28MhW {
  color: #1a1a1a;
  font-size: 22px;
  letter-spacing: -0.28px;
  margin-top: 30px;
}
.devInfo___3Hde- .grade___Xww6s {
  margin-top: 12px;
  display: inline-block;
  padding: 0 20px;
  border-radius: 20px;
  height: 36px;
  line-height: 36px;
  background: #e1e6ec;
  font-size: 12px;
  color: #666666;
}
.devInfo___3Hde- .introduce___2892W {
  margin-top: 18px;
  font-size: 12px;
  color: #999999;
  letter-spacing: -0.15px;
  line-height: 22px;
}
.devInfo___3Hde- .addressWrap___1NeWA {
  margin-top: 40px;
  font-size: 14px;
  color: #1a1a1a;
  letter-spacing: -0.15px;
  line-height: 22px;
  font-weight: bold;
}
