.my-search___2QNAM .ant-input-search .ant-input {
  height: 42px;
  border-radius: 24px 0 0 24px;
}
.my-search___2QNAM .ant-input-search .ant-input-suffix {
  font-size: 24px;
}
.my-search___2QNAM .ant-input-search .ant-input-group-addon {
  background: none;
}
.my-search___2QNAM .ant-input-search .ant-input-search-button {
  height: 48px;
  width: 46px;
  border: 1px solid #d9d9d9;
  border-left: none;
  border-radius: 0 24px 24px 0 !important;
}
