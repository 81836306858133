.page-index___2PaBl {
  width: 100%;
  min-width: 1280px;
  background: #fff;
  overflow: hidden;
}
.page-index___2PaBl .center___371gB {
  width: 1280px;
  margin: 0 auto;
  overflow: hidden;
}
