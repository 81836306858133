.modal___3YXYV .coupons___2G5ai {
  width: 390px;
  border: 2px solid #005fe173;
  cursor: pointer;
}
.modal___3YXYV .coupons___2G5ai button {
  width: 88%;
  display: inline-block;
  margin-bottom: 0px;
  margin-right: 0px;
  border: none;
}
.modal___3YXYV .coupons___2G5ai p {
  border-radius: 0;
}
.modal___3YXYV .coupons___2G5ai .ex___1jJ-q {
  writing-mode: vertical-lr;
  display: inline-block;
  position: absolute;
  background: #005fe173;
  height: 215px;
  width: 47px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 8px;
}
.modal___3YXYV .coupons___2G5ai .rx___2XfCf {
  writing-mode: vertical-lr;
  display: inline-block;
  position: absolute;
  background: #becfd8;
  height: 215px;
  width: 47px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-weight: bold;
  letter-spacing: 8px;
  cursor: not-allowed;
}
.modal___3YXYV .ant-modal-title {
  font-weight: bold;
}
.modal___3YXYV .ant-modal-body {
  height: 550px;
}
.modal___3YXYV .ant-pagination {
  text-align: right;
}
.modal___3YXYV .ant-pagination {
  text-align: right;
  position: absolute;
  right: 10px;
  bottom: 50px;
}
